<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Submit in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		<ConfirmDialog group="positionDialog"></ConfirmDialog>
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 p-fluid">
			<div class="card">
				<div class="card" style="
						background-color: #da3831;
						height: 2em;
						margin: 0 0 2em 0;
					">
					<h5 style="color: white; margin-top: -10px">
						Add/Edit Planning Order
					</h5>
				</div>
				<div class="p-fluid grid formgrid">
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText id="inputtext" type="text" v-model="planningOrderNum" disabled />
							<label for="inputtext">Planning Order No</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText id="inputtext" type="text" v-model="revisition" disabled />
							<label for="inputtext">Rev</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<!-- <InputText id="inputtext" type="text" v-model="planningOrderDate" v-bind:disabled="planningOrderDate"/> -->
							<Calendar id="dateformat" v-model="planningOrderDate" dateFormat="dd-M-yy" disabled />
							<label for="inputtext">Planning Order Date</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText id="inputtext" type="text" v-model="planningOrderStatus" disabled />
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText id="inputtext" type="text" v-model="supplier" disabled />
							<label for="inputtext">Supplier</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<Calendar id="dateformat" v-model="respondDate" dateFormat="dd-M-yy"
								:disabled="respondStatus" />
							<label for="inputtext">Must Responded Date</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText id="inputtext" type="text" v-model="respondBy" disabled />
							<label for="inputtext">Respond By</label>
						</span>
					</div>
				</div>
				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-4 lg:col-1 lg:mb-5">
						<Button label="Print" @click="printPage()"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-4 lg:col-1 lg:mb-5" v-if="!this.changeHold">
						<Button label="Hold" class="p-button-warning" @click="changeStatusHold()"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-4 lg:col-1 lg:mb-5" v-if="!this.changeUnhold">
						<Button label="Unhold" class="p-button-warning" @click="changeStatusUnhold()"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 p-fluid">
			<div class="card">
				<div class="card" style="
						background-color: #da3831;
						height: 2em;
						margin: 0 0 2em 0;
					">
					<h5 style="color: white; margin-top: -10px">Submit PO</h5>
				</div>
				<div v-if="this.submitpo" class="p-fluid grid formgrid">
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<AutoComplete v-model="location" :suggestions="filteredLocation"
								@complete="searchLocation($event)" :dropdown="true" field="ship_to_desc" forceSelection>
								<template #item="slotProps">
									<div class="ship to desc-item">
										<div class="ml-2">
											{{ slotProps.item.ship_to_desc }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">Location</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<AutoComplete v-model="poType" :suggestions="filteredPoType"
								@complete="searchPoType($event)" :dropdown="true" field="name" forceSelection>
								<template #item="slotProps">
									<div class="supplier-item">
										<div class="ml-2">
											{{ slotProps.item.name }}
										</div>
									</div>
								</template>
							</AutoComplete>
							<label for="inputtext">PO Type</label>
						</span>
					</div>
					<div class="col-12 mb-3 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<InputText id="inputtext" type="text" v-model="poNumber" :maxlength="max" />
							<label for="inputtext">PO Number</label>
						</span>
					</div>
					<div class="col-12 mb-5 lg:col-2 lg:mb-0">
						<span class="p-float-label">
							<Dropdown id="dropdown" :options="typeInputList" v-model="typeInput" optionLabel="type">
							</Dropdown>
						</span>
					</div>
					<div class="col-12 mb-1 lg:col-2 lg:mb-0">
						<Button type="button" label="Search" icon="pi pi-search" @click="openResponsive"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
				</div>
				<div class="p-fluid grid formgrid" style="margin-top: 1.5rem; margin-bottom: 0.5rem">
					<div class="col-6 mb-1 lg:col-2 lg:mb-0">
						<Button label="Planning Order" class="p-button-secondary p-button-text" @click="openPo"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-1 lg:col-1 lg:mb-0">
						<Button label="Terms" class="p-button-secondary p-button-text" @click="openTerms"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
				</div>

				<div class="col-12 mb-5" v-if="!this.changeTable">
					<DataTable :value="filtered ? filtered : planningOrderTable" dataKey="plan_order_line_id"
						:paginator="true" :rows="10" v-model:filters="filters" v-model:selection="selectedPo"
						:rowClass="rowClass" removableSort
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]" :loading="displayLoading" selectionMode="multiple"
						:metaKeySelection="false" stateStorage="session" stateKey="tableSession"
						@rowSelect="onRowSelect" @rowUnselect="onRowUnselect"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll" :scrollable="true" scrollHeight="600px" scrollDirection="both"
						@page="onPage($event)">
						<!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
						<!-- <Column header="#" headerStyle="width:3em">
                    <template #body="slotProps">
                        {{slotProps.index}}
                    </template>
                </Column> -->
						<Column header="Line" style="width: 50px" frozen>
							<template #body="slotProps">
								{{ slotProps.index + 1 + this.page }}
							</template>
						</Column>
						<Column field="item_num" header="Item Number" sortable="" style="width: 12%" frozen></Column>
						<Column field="item_desc" header="Item Description" :sortable="true" style="width: 15%">
						</Column>
						<Column field="gbpa_no" header="No GBPA" :sortable="true" style="width: 15%">
							<template #body="slotProps">
								<p>{{ slotProps.data.gbpa_no ? slotProps.data.gbpa_no : "-" }}</p>
							</template>
						</Column>
						<Column header="UOM" style="width: 9%">
							<template #body="slotProps">
								<Dropdown v-model="slotProps.data.uom" :options="isiUom" optionLabel="unit_of_measure"
									optionValue="unit_of_measure" placeholder="Select UOM" :editable="true"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<Column header="Qty" style="width: 9%">
							<template #body="slotProps">
								<InputNumber id="locale-german" v-model="slotProps.data.plan_qty" mode="decimal"
									locale="id-ID" inputStyle="text-align: right" :minFractionDigits="2"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<!-- <Column header="Original Qty" style="width: 9%">
							<template #body="slotProps">
								{{ slotProps.data.original_quantity ?? 0 }}
							</template>
						</Column> -->
						<Column header="Rate Currency" style="width: 9%">
							<template #body="slotProps">
								<InputNumber id="locale-german" v-model="slotProps.data.rate_currency" mode="decimal"
									locale="id-ID" inputStyle="text-align: right" :minFractionDigits="2"
									v-bind:disabled="disabled[slotProps.index] || slotProps.data.gbpa_no !== null" />
							</template>
						</Column>
						<Column header="Deal Currency" style="width: 12%">
							<template #body="slotProps">
								<Dropdown :disabled="slotProps.data.gbpa_no !== null"
									v-model="slotProps.data.deal_currency" :options="currenciesList"
									optionLabel="currency_code" optionValue="currency_code" style="width: 100%" />
							</template>
						</Column>
						<Column field="ship_to_desc" header="Location" :sortable="true" style="width: 9%">
						</Column>
						<Column header="Note to Supplier" style="width: 15%">
							<template #body="slotProps">
								<InputText type="text" v-model="slotProps.data.note_to_supplier"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<Column field="currency" header="Currency" :sortable="true" style="width: 9%">
							<!-- <template #body="{ data }">
								<div v-if="data.name !== 'NEW'">
									{{ data.currency }}
								</div>
								<div v-else>
									{{ '' }}
								</div>
							</template> -->
						</Column>
						<Column field="new_confirm_price" header="Confirm Price" dataType="numeric" :sortable="true"
							style="width: 9%">
							<!-- <template #body="{ data }">
								<div v-if="data.name !== 'NEW'">
									{{ formatCurrency(data.confirm_price) }}
								</div>
								<div v-else>{{ '' }}</div>
							</template> -->
						</Column>
						<Column field="new_confirm_qty" header="Confirm Qty" dataType="numeric" :sortable="true"
							style="width: 9%">
							<!-- <template #body="{ data }">
								<div v-if="data.name !== 'NEW'">
									{{ formatCurrency(data.confirm_qty) }}
								</div>
								<div v-else>{{ '' }}</div>
							</template> -->
						</Column>
						<Column field="remarks" header="Remark" :sortable="true" style="width: 9%">
							<!-- <template #body="{ data }">
								<div v-if="data.name !== 'NEW'">
									{{ data.remarks }}
								</div>
								<div v-else>{{ '' }}</div>
							</template> -->
						</Column>
						<Column header="Reason for not Accept" style="width: 9%">
							<template #body="slotProps">
								<!-- <Textarea v-model="slotProps.data.remarks3" :autoResize="true" rows="5" cols="30" /> -->
								<InputText type="text" v-model="slotProps.data.remarks3"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<Column field="name" header="Line Status" :sortable="true" style="width: 9%">
						</Column>
					</DataTable>
				</div>

				<div class="col-12 mb-5" v-if="this.changeTable">
					<DataTable :value="filtered ? filtered : planningOrderTable" dataKey="plan_order_line_id"
						:paginator="true" :rows="10" v-model:filters="filters" v-model:selection="selectedPo"
						removableSort
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]" :loading="displayLoading" :metaKeySelection="false"
						stateStorage="session" stateKey="tableSession"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll" :scrollable="true" scrollHeight="600px" @page="onPage($event)">
						<!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
						<Column header="Line" style="width: 50px">
							<template #body="slotProps">
								{{ slotProps.index + 1 + this.page }}
							</template>
						</Column>
						<Column field="item_num" header="Item Number" :sortable="true" sortField="item_num"
							style="width: 9%">
						</Column>
						<Column field="item_desc" header="Item Description" :sortable="true" style="width: 9%"></Column>
						<Column header="Arrival at Site" style="width: 9%">
							<template #body="slotProps">
								<Calendar id="dateformat" v-model="slotProps.data.need_by_date" dateFormat="dd-M-yy"
									style="text-align: center" v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<Column header="Freight" style="width: 9%">
							<template #body="slotProps">
								<Dropdown v-model="slotProps.data.freight_terms" :options="isiFreight"
									optionLabel="name" optionValue="name" placeholder="Select Freight" :editable="true"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<Column header="ToP" style="width: 9%">
							<template #body="slotProps">
								<Dropdown v-model="slotProps.data.terms_id" :options="isiTop" optionLabel="name"
									optionValue="term_id" placeholder="Select ToP" :editable="true"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
						<Column sortField="confirm_ship_date_sort" header="Confirm Arrival at Site" :sortable="true"
							field="confirm_ship_date" style="width: 9%">
							<!-- <template #body="slotProps">
								<div v-if="slotProps.data.name !== 'NEW'">
									{{ slotProps.data.confirm_ship_date }}
								</div>
								<div v-else>
									{{ '' }}
								</div>
							</template> -->
						</Column>
						<Column header="Incoterm" style="width: 9%">
							<template #body="slotProps">
								<Dropdown v-model="slotProps.data.fob_sp" :options="isiIncoterm" optionLabel="name"
									optionValue="value" placeholder="Select Incoterm" :editable="true"
									v-bind:disabled="disabled[slotProps.index]" />
							</template>
						</Column>
					</DataTable>
				</div>

				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-2 lg:col-2 lg:mb-0">
						<Button type="button" label="Back" class="p-button-secondary" @click="back()"
							:disabled="this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-2 lg:col-2 lg:mb-0">
						<Button type="button" label="Cancel" class="p-button-danger" @click="confirm('C')"
							:disabled="this.button || this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-2 lg:col-2 lg:mb-0">
						<Button type="button" label="Save" @click="save()"
							:disabled="this.button || this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-2 lg:col-2 lg:mb-0">
						<Button type="button" label="Submit" class="p-button-success" @click="submit()"
							:disabled="this.button || this.planningOrderStatus === 'CANCELED'" />
					</div>
					<div class="col-6 mb-2 lg:col-2 lg:mb-0">
						<Button type="button" label="Not Accept" class="p-button-danger" @click="notAccept()"
							:disabled="this.button || this.planningOrderStatus === 'CANCELED'" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<Dialog header="Search Po Number" v-model:visible="displayResponsive" :breakpoints="{ '960px': '75vw' }"
		:style="{ width: '75vw' }" :modal="true">
		<div class="p-fluid grid formgrid" style="margin-top: 1.5rem">
			<div class="col-6 mb-2 lg:col-2 lg:mb-5">
				<span class="p-float-label">
					<AutoComplete v-model="popUpPo" :suggestions="filteredPopUpPo" @complete="searchPopUpPo($event)"
						:dropdown="true" field="segment1" forceSelection>
						<template #item="slotProps">
							<div class="country-item">
								<div class="ml-2">
									{{ slotProps.item.segment1 }}
								</div>
							</div>
						</template>
					</AutoComplete>
					<label for="inputtext">PO Number</label>
				</span>
			</div>
			<div class="col-6 mb-2 lg:col-2 lg:mb-2">
				<Button type="button" label="Search" @click="searchPo()" />
			</div>
		</div>
		<DataTable :value="tablepopuppo" dataKey="plan_order_line_id" :paginator="true" :rows="5"
			v-model:filters="filters"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			:rowsPerPageOptions="[5, 10, 25]" :loading="loading"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll"
			:scrollable="true" scrollHeight="flex">
			<Column field="segment1" header="PO Number" :sortable="true"></Column>
			<Column field="vendor_name" header="Supplier" :sortable="true"></Column>
			<Column field="employeename" header="Buyer" :sortable="true"></Column>
			<Column field="location_code" header="Ship To"> </Column>
			<Column field="attribute1" header="PO Type" :sortable="true" style="text-align: center">
			</Column>
			<Column field="creation_date" header="Creation Date" :sortable="true">
				<template #body="slotProps">
					{{
			new Date(
				slotProps.data.creation_date
			).toLocaleDateString('id-ID', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			})
		}}
				</template>
			</Column>
			<Column field="closed_code" header="Status" :sortable="true">
			</Column>
			<Column field="authorization_status" header="Approval Status" :sortable="true" style="text-align: center">
			</Column>
		</DataTable>
		<template #footer>
			<Button label="No" icon="pi pi-times" @click="closeResponsive" class="p-button-text" />
			<Button label="Yes" icon="pi pi-check" @click="submitResponsive" autofocus />
		</template>
	</Dialog>

	<Dialog header="Validation" v-model:visible="displayPilih" :breakpoints="{ '960px': '20vw' }"
		:style="{ width: '10vw' }" :modal="true">
		<div class="confirmation-content">
			<span>Please Select Planning Order First</span>
		</div>
	</Dialog>

	<Dialog header="Validation" v-model:visible="displayValidasi" :breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }" :modal="true">
		<div class="confirmation-content">
			<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
			<span>Reason for Not Accept is empty</span>
		</div>
	</Dialog>

	<Dialog header="Validation" v-model:visible="displaySubmit" :breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }" :modal="true">
		<div class="confirmation-content">
			<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
			<span>Location, PO Type, dan PO Number is empty</span>
		</div>
	</Dialog>
	<Dialog header="Validation" v-model:visible="displayQuantity" :breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }" :modal="true">
		<div class="confirmation-content">
			<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
			<span>Qty Empty</span>
		</div>
	</Dialog>
</template>

<script>
/* eslint-disable */
import PlanningOrder from '../../../service/PlanningOrderService';
import CreatePoService from '../../../service/CreatePoService';
import AuthService from '../../../service/AuthService';
import ProgressModal from '../../components/ProgressModal.vue';
import Swal from "sweetalert2";

import SimpleCrypto from 'simple-crypto-js';
import { UrlTab } from '../../../constants/UrlTab';
export default {
	name: 'planningorder-list',
	components: { ProgressModal },
	props: {
		poTable: Array,
	},
	data() {
		return {
			typeInputList: [{ id: 1, type: "Create New" }, { id: 2, type: "Add Line" }],
			typeInput: { id: 1, type: "Create New" },
			idValue: this.$route.params.id,
			idDetail: null,
			isiDetail: null,
			planningOrderNum: null,
			planningOrderDate: null,
			supplier: null,
			respondBy: null,
			revisition: null,
			planningOrderStatus: null,
			respondDate: null,
			location: null,
			isiLocation: null,
			filteredLocation: null,
			displayResponsive: false,
			locationChanged: null,
			changeTable: false,
			planningOrderTable: null,
			selectedPo: null,
			selectedTerms: null,
			termsTable: null,
			poType: null,
			isiPoType: null,
			filteredPoType: null,
			selectedPoType: null,
			uom: null,
			isiUom: null,
			freight: null,
			isiFreight: null,
			tablepopuppo: null,
			arrivalDate: null,
			top: null,
			isiTop: null,
			incoterm: null,
			isiIncoterm: null,
			status: null,
			changeStatus: null,
			unholdStatus: null,
			changeHold: false,
			changeUnhold: false,
			popUpPo: null,
			filteredPopUpPo: null,
			poNumber: null,
			qty: null,
			max: 20,
			testData: null,
			submitpo: false,
			isInvalid: true,
			displayValidasi: false,
			displayPilih: false,
			something: null,
			disabled: [],
			something: null,
			line: null,
			untukIndex: null,
			payloadNotAccept: null,
			displaySubmit: false,
			respondStatus: true,
			button: false,
			search: null,
			filtered: null,
			ubahConfirm: null,
			userId: null,
			auth: true,
			displayLoading: false,
			displayQuantity: false,
			filtered: null,
			page: null,
			progress: false,
			monthNames: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			monthShortNames: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			],
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Planning Order', to: '/mgm/settings/planningorder' },
				{ label: 'Detail Planning Order', to: this.$route.path },
			],
		};
	},
	created() {
		if (this.idValue != 0) {
			sessionStorage.setItem('printPoId', this.idValue);
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					const payload = {
						idValue: this.idValue,
						leveltenant: sessionStorage.leveltenant,
					};
					PlanningOrder.getIdDetail(payload).then((response) => {
						this.isiDetail = response.data.data;
						this.planningOrderNum =
							response.data.data[0].plan_order_num;
						let date = new Date(
							response.data.data[0].plan_order_date
						);
						date.setDate(date.getDate());
						this.respondDate = new Date(
							response.data.data[0].response_date
						);
						this.supplier = response.data.data[0].vendor_name;
						this.respondBy = response.data.data[0].response_by;
						// this.revisition = response.data.data[0].revisition;
						if (response.data.data[0].revisition != null) {
							this.revisition = response.data.data[0].revisition;
						} else {
							this.revisition = 0;
						}
						this.planningOrderStatus = response.data.data[0].status;
						if (response.data.data[0].status !== 'CONFIRMED') {
							this.respondStatus = false;
						}
						if (response.data.data[0].status === 'HOLD') {
							this.changeHold = true;
							this.changeUnhold = false;
							this.submitpo = false;
						}
						if (response.data.data[0].status !== 'HOLD') {
							this.changeHold = false;
							this.changeUnhold = true;
							this.submitpo = true;
						}
						let dateResp = new Date(
							response.data.data[0].plan_order_date
						);
						date.setDate(date.getDate() + 3);
						this.planningOrderDate = dateResp;
						if (this.planningOrderNum !== null) {
							const payload = {
								id: response.data.data[0].plan_order_id,
								vendor: response.data.data[0].vendor_id,
							};
							PlanningOrder.getTableDetailPo(payload).then(
								(response) => {
									this.planningOrderTable =
										response.data.data;
									for (
										let i = 0;
										i < this.planningOrderTable.length;
										i++
									) {

										this.planningOrderTable[i]['line'] = (i+1);

										if (
											this.planningOrderTable[i].name ==
											'COMPLETED'
										) {
											this.disabled[i] = true;
											this.something =
												this.planningOrderTable[
													i
												].confirm_price.toLocaleString(
													'id-ID'
												);
										}
										if (
											this.planningOrderTable[i]
												.note_to_supplier == 'null'
										) {
											this.planningOrderTable[
												i
											].note_to_supplier = '';
										}
										if (
											this.planningOrderTable[i]
												.remarks == 'null'
										) {
											this.planningOrderTable[i].remarks =
												'';
										} else {
											this.disabled[i] = false;
										}
									}
									for (let id of this.planningOrderTable) {
										if (id.name === 'NEW') {
											id.confirm_ship_date = '';
											id.currency = '';
											id.confirm_price = '';
											id.confirm_qty = '';
											id.remarks = '';
										}
										// if (id.name !== 'NEW'){
										// 	id.confirm_price = id.confirm_price;
										// 	id.confirm_qty = id.confirm_qty;
										// }
										id.confirm_ship_date_sort = new Date(
											id.confirm_ship_date
										).getTime();
										id.confirm_ship_date =
											id.confirm_ship_date
												? this.dateFormat2(
													id.confirm_ship_date
												)
												: '';
										if (
											id.confirm_ship_date ==
											'1 Jan 1970 ' ||
											id.need_by_date ==
											'NaN undefined NaN '
										) {
											id.confirm_ship_date = '';
										}
										if (id.need_by_date == null) {
											id.need_by_date = '';
										}
										if (id.need_by_date != null) {
											id.need_by_date = new Date(
												id.need_by_date
											);
										}
										if (id.confirm_ship_date == null) {
											id.confirm_ship_date = '';
										} else {
											id.need_by_date = new Date(
												id.need_by_date
											);
											id.new_confirm_price =
												this.formatCurrency(
													id.confirm_price
												);
											id.new_confirm_qty =
												this.formatCurrency(
													id.confirm_qty
												);
										}
									}
								}
							);

							PlanningOrder.getTerms(payload).then((response) => {
								this.termsTable = response.data.data;
								for (let id of this.termsTable) {
									id.confirm_ship_date = this.dateFormat2(
										new Date(id.confirm_ship_date)
									);
									if (id.confirm_ship_date == '1 Jan 1970 ') {
										id.confirm_ship_date = '';
									}
									if (id.need_by_date == null) {
										id.need_by_date = '';
									}
									if (id.need_by_date != null) {
										id.need_by_date = new Date(
											id.need_by_date
										);
									}
									if (id.confirm_ship_date == null) {
										id.confirm_ship_date = '';
									} else {
										id.need_by_date = new Date(
											id.need_by_date
										);
									}
								}
							});
						}
					});
				}
			});
		}
		this.getLocation();
		this.getPoType();
		this.getUom();
		this.getFreight();
		this.getTablePoNumber();
		this.getTop();
		this.getIncoterm();
		this.getCurrencies();
	},
	watch: {
		selectedPo() {
			for (var id of this.selectedPo) {
				if (
					id.name == 'COMPLETED' ||
					id.name == 'PROCESSED' ||
					id.name == 'NEW' ||
					id.name == 'CANCELLED' ||
					this.selectedPo.length == 0
				) {
					this.button = true;
				} else {
					this.button = false;
				}
			}
		},
		location(newQuestion) {
			if (newQuestion !== null) {
				console.log(this.planningOrderTable, "P")
				this.selectedPo = [];
				this.filtered = this.planningOrderTable.filter((item) =>
					item.ship_to_desc.toLowerCase() === newQuestion.ship_to_desc.toLowerCase()
				)
				let idx = 0;
				this.filtered.forEach((Q) => {
					Q['line'] = (idx+1);
					idx++;
				});
			} else {
				this.selectedPo = [];
				this.filtered = null
			}
		},
	},
	mounted() { },
	computed: {
	},
	methods: {
		onPage(event) {
			this.page = event.page * event.rows;
		},
		searchPo() {
			let payload = {
				segment1: this.popUpPo.segment1,
			};
			PlanningOrder.searchPopPo(payload).then((response) => {
				this.tablepopuppo = response.data.data;
			});
		},
		testData(event) {
			cosnole.log('wdawda eek', event);
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		formatDateSend(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			return [year, month, day].join('-');
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate.replace('-', '');
		},
		dateFormat1(d) {
			var t = new Date(d);
			return (
				t.getDate() +
				' ' +
				this.monthNames[t.getMonth()] +
				' ' +
				t.getFullYear()
			);
		},
		dateFormat2(d) {
			var t = new Date(d);
			return (
				t.getDate() +
				' ' +
				this.monthShortNames[t.getMonth()] +
				' ' +
				t.getFullYear() +
				' '
			);
		},
		getLocation() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					let payload = this.idValue;
					PlanningOrder.getLocation(payload).then((response) => {
						this.isiLocation = response.data.data;
					});
				}
			});
		},
		searchLocation(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLocation = [...this.isiLocation];
				} else {
					this.filteredLocation = this.isiLocation.filter((item) => {
						return item.ship_to_desc
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		getPoType() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					PlanningOrder.getPoType().then((response) => {
						this.isiPoType = response.data.data;
					});
				}
			});
		},
		getFreight() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					if (sessionStorage.companydb !== null) {
						let payload = {
							dbValue: sessionStorage.companydb,
						};
						PlanningOrder.getFreight(payload).then((response) => {
							this.isiFreight = response.data.data;
						});
					}
				}
			});
		},
		getTop() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					let payload = {
						isi: sessionStorage.companydb,
					};
					PlanningOrder.getTop(payload).then((response) => {
						this.isiTop = response.data.data;
					});
				}
			});
		},
		getIncoterm() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					if (sessionStorage.companydb !== null) {
						const payload = sessionStorage.companydb;
						PlanningOrder.getIncoterm(payload).then((response) => {
							this.isiIncoterm = response.data.data;
						});
					}
				}
			});
		},
		getTablePoNumber() {
			// AuthService.whoAmi().then(response => {
			//     if (response === 200 ){
			PlanningOrder.getTablePoNumber().then((response) => {
				this.tablepopuppo = response.data.data;
			});
			//     }
			// })
		},
		searchPoType(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPoType = [...this.isiPoType];
				} else {
					this.filteredPoType = this.isiPoType.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		getUom() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					if (sessionStorage.companydb !== null) {
						const payload = {
							dbValue: sessionStorage.companydb,
						};
						PlanningOrder.getUom(payload).then((response) => {
							this.isiUom = response.data.data;
						});
					}
				}
			});
		},
		getCurrencies() {
			CreatePoService.getAllCurrencies(
				sessionStorage.companydb
			).then((value) => {
				this.currenciesList = value.data.data;
			});
		},
		changeStatusHold() {
			if (
				this.planningOrderStatus !== 'H' ||
				this.planningOrderStatus !== 'HOLD'
			) {
				this.changeHold = true;
				this.changeUnhold = false;
				this.submitpo = false;
				this.respondStatus = false;
				var date = new Date();
				var respondDate =
					('00' + (date.getMonth() + 1)).slice(-2) +
					'/' +
					('00' + date.getDate()).slice(-2) +
					'/' +
					date.getFullYear() +
					' ' +
					('00' + date.getHours()).slice(-2) +
					':' +
					('00' + date.getMinutes()).slice(-2) +
					':' +
					('00' + date.getSeconds()).slice(-2);
				// let date = this.respondDate.toLocaleDateString("id-ID", options)
				let payload = {
					status: 'H',
					id: this.idValue,
					date: respondDate,
				};
				PlanningOrder.changeStatus(payload).then((response) => {
					this.$toast.add({
						severity: 'success',
						summary: 'Status Change',
						detail: 'Change Status to Hold',
						life: 3000,
					});
					this.planningOrderStatus = 'HOLD';
				});
			}
		},
		changeStatusUnhold() {
			if (
				this.planningOrderStatus === 'H' ||
				this.planningOrderStatus === 'HOLD'
			) {
				this.changeUnhold = true;
				this.changeHold = false;
				this.submitpo = true;
				this.respondStatus = true;
				var respondDate = this.formatDateDexa(new Date());
				let payload = {
					status: 'C',
					id: this.idValue,
					date: respondDate,
				};
				PlanningOrder.changeStatus(payload).then((response) => {
					this.$toast.add({
						severity: 'success',
						summary: 'Status Change',
						detail: 'Change Status to Confirmed',
						life: 3000,
					});
					this.planningOrderStatus = 'CONFIRMED';
				});
			}
		},
		searchPopUpPo(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPopUpPo = [...this.tablepopuppo];
				} else {
					this.filteredPopUpPo = this.tablepopuppo.filter((item) => {
						return item.segment1
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		openResponsive() {
			this.displayResponsive = true;
		},
		closeResponsive() {
			this.displayResponsive = false;
			if (this.popUpPo != null || this.popUpPo.length != 0) {
				this.getTablePoNumber();
				this.popUpPo = null;
			}
		},
		submitResponsive() {
			this.displayResponsive = false;
			this.poNumber = this.popUpPo.segment1;
			// if (this.popUpPo != null || this.popUpPo.length != 0){
			// 	this.getTablePoNumber();
			// }
		},
		openPo() {
			this.changeTable = false;
		},
		openTerms() {
			this.changeTable = true;
		},
		back() {
			this.$router.push({
				name: 'planningOrder',
			});
		},
		onRowSelect(event) {

			console.log(event.data.name, 'EVVVV')

			if (event.data.name == 'NEW' || event.data.name == 'PROCESSED' || event.data.name == 'NOT ACCEPT' || event.data.name == 'CANCELLED') {
				const index = this.selectedPo.findIndex(
					(something) =>
						something.name === 'NEW' ||
						something.name == 'PROCESSED' ||
						something.name == 'NOT ACCEPT' || something.name == 'CANCELLED'
				);
				this.selectedPo.splice(index, 1);
			}
			// else{
			// }
		},
		onRowUnselect(event) {
			// this.$toast.add({severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.plan_order_line_id, life: 3000});
		},
		AllTheSame(array) {
			var first = array[0];
			return array.every(function (element) {
				return element.name === first.name;
			});
		},
		save() {
			if (this.selectedPo == null || this.selectedPo.length == 0) {
				this.displayPilih = true;
			} else {
				AuthService.whoAmi().then((response) => {
					var respondDate = this.formatDateDexa(new Date());
					var lopez = {
						last_updated_by: response.data.data.id,
						last_updated_date: respondDate,
					};
					var something = [];
					this.selectedPo.forEach((element) => {
						const index = this.planningOrderTable.findIndex(
							(something) =>
								something.plan_order_line_id ===
								element.plan_order_line_id
						);
						this.something = Object.assign(
							this.planningOrderTable[index],
							lopez
						);
						something.push(this.something);
					});
					var checkPO = this.AllTheSame(this.planningOrderTable);
					var statusHeader;
					var last_updated_date = this.formatDateDexa(new Date());
					var dateHeader = this.formatDateDexa(
						new Date(this.respondDate)
					);
					if (checkPO) {
						this.planningOrderTable.forEach((element) => {
							if (element.name == 'PROCESSED') {
								statusHeader = 'A';
							}
							if (element.name == 'TO BE PROCESSED') {
								statusHeader = 'C';
							} else {
								statusHeader = 'D';
							}
						});
					} else if (!checkPO) {
						statusHeader = '';
					}
					let header = {
						id: parseInt(this.idValue),
						date: dateHeader,
						status: statusHeader,
						last_updated_by: response.data.data.id,
						last_updated_date: last_updated_date,
					};
					let payload = {
						isi: something,
						header: header,
					};
					for (let id of payload.isi) {
						if (
							id.need_by_date == null ||
							id.need_by_date.length == 0
						) {
							id.need_by_date = '';
						}
						if (
							id.confirm_ship_date == null ||
							id.confirm_ship_date.length == 0
						) {
							id.confirm_ship_date = '';
						}
						if (id.remarks == null) {
							id.remarks = '';
						}
						if (id.remarks3 == null) {
							id.remarks3 = '';
						} else {
							// id.need_by_date = this.formatDateDexa(
							// 	new Date(id.need_by_date)
							// );
							// id.confirm_ship_date = this.formatDateDexa(
							// 	new Date(id.confirm_ship_date)
							// );
							id.remarks = id.remarks;
							id.remarks3 = id.remarks3;
						}
					}

					PlanningOrder.saveOption(payload)
						.then
						// window.location.reload()
						();
					this.$toast.add({
						severity: 'success',
						summary: 'Save Data',
						detail: 'Data has been saved',
						life: 3000,
					});
					for (let id of payload.isi) {
						id.need_by_date = new Date(id.need_by_date);
						// id.confirm_ship_date = this.formatDateSend(new Date(id.confirm_ship_date))
					}
					sessionStorage.removeItem('tableSession');
					this.selectedPo = [];
				});
			}
		},
		submit() {
			if (this.selectedPo == null || this.selectedPo.length == 0) {
				this.displayPilih = true;
				this.button = false;
				this.auth = false;
			} else if (
				this.selectedPo.length != 0 &&
				(this.location == null ||
					this.poType == null ||
					this.poNumber == null)
			) {
				this.button = false;
				this.displaySubmit = true;
				this.auth = false;
			} else if (
				this.selectedPo.length != 0 &&
				(this.location.ship_to_desc.length != 0 ||
					this.poType.name.length != 0 ||
					this.poNumber.length != 0)
			) {
				this.progress = true;
				this.displayLoading = true;
				AuthService.whoAmi().then((response) => {
					this.button = true;
					let payloadUser = {
						user_id: response.data.data.id,
					};
					let idKkt = response.data.data.id;
					PlanningOrder.getEmployeeID(payloadUser).then(
						(response) => {
							this.userId = response.data.data[0].employee_id;
							var last_updated_date = this.formatDateDexa(
								new Date()
							);
							var lopez = {
								employee_id: this.userId,
								last_updated_by: idKkt,
								last_updated_date: last_updated_date,
							};
							var po_Tambahan = {
								ship_to_desc: this.location.ship_to_desc,
								po_type: this.poType.name,
								po_number: this.poNumber,
								db_instance: sessionStorage.companydb,
							};
							var something = [];
							let dateHeader = this.formatDateDexa(
								new Date(this.respondDate)
							);
							var checkPO = this.AllTheSame(
								this.planningOrderTable
							);
							var statusHeader;
							var status = {
								statusValue: 'S',
							};
							if (checkPO) {
								this.planningOrderTable.forEach((element) => {
									if (element.name == 'PROCESSED') {
										statusHeader = 'A';
									} else if (
										element.name == 'TO BE PROCESSED'
									) {
										statusHeader = 'C';
									} else {
										statusHeader = 'D';
									}
								});
							} else if (!checkPO) {
								statusHeader = '';
							}
							let header = {
								id: Number(this.idValue),
								date: dateHeader,
								status: statusHeader,
								last_updated_by: idKkt,
								last_updated_date: last_updated_date,
							};
							this.selectedPo.forEach((element) => {
								const index = this.planningOrderTable.findIndex(
									(something) =>
										something.plan_order_line_id ===
										element.plan_order_line_id
								);
								this.something = Object.assign(
									element,
									this.planningOrderTable[index],
									lopez,
									po_Tambahan,
									status
								);
								something.push(this.something);
							});

							let payload = {
								isi: something,
								header: header,
							};
							for (var id of payload.isi) {
								if (id.plan_qty == 0 || id.plan_qty == null) {
									this.displayQuantity = true;
									this.displayLoading = false;
									this.button = false;
								} else {
									id.confirm_ship_date = this.formatDateDexa(
										new Date(id.confirm_ship_date)
									);
									id.need_by_date = this.formatDateDexa(
										new Date(id.need_by_date)
									);
									id.rate_date = this.formatDateDexa(
										new Date(id.last_updated_date)
									);
									id.eta_war = this.formatDateDexa(
										new Date(id.eta_war)
									);
									id.last_updated_date = this.formatDateDexa(
										new Date(id.last_updated_date)
									);
									id.remarks3 = id.remarks3;
									id.remarks = id.remarks;
									id.confirm_price = parseFloat(
										id.confirm_price
									);
									id.confirm_qty = parseFloat(id.confirm_qty);
									id.confirm_ship_date = this.formatDateDexa(
										id.confirm_ship_date
									);
									if (id.charge_num == null) {
										id.charge_num = 0;
									}
									if (
										id.remarks == null ||
										id.remarks == 'null'
									) {
										id.remarks = '';
									}
									if (
										id.remarks3 == null ||
										id.remarks3 == 'null'
									) {
										id.remarks3 = '';
									}
									if (
										id.jit_flag == null ||
										id.jit_flag == 'null'
									) {
										id.jit_flag = '';
									}
									if (
										id.note_to_supplier == null ||
										id.note_to_supplier == 'null'
									) {
										id.note_to_supplier = '';
									}
								}
							}
							console.log(this.typeInput, "TYPE INPUT:::");
							payload.typeInput = this.typeInput.type;
							console.log(payload, "PAYLOAD::")
							PlanningOrder.submitApi(payload).then(
								(response) => {
									switch (true) {
										case response.status === 200:
											// PlanningOrder.submitPo(payload).then();
											this.progress = false;
											this.displayLoading = true;
											// this.$toast.add({
											// 	severity: 'success',
											// 	summary: 'Status Change',
											// 	detail: 'Change Status to Processed',
											// 	life: 3000,
											// });

											for (var id of this.selectedPo) {
												id.name = 'PROCESSED';
											}
											this.progress = false;
											this.displayLoading = false;
											this.button = false;
											PlanningOrder.insertToInterface(
												payload
											);
											this.selectedPo = [];
											Swal.fire({
												title: "Success To Submit",
												text: "Change Status to Processed",
												icon: "success", 
												showConfirmButton: false, 
												timer: 2000 
											}).then(() => {
												for (var id of this.selectedPo) {
													id.name = 'PROCESSED';
												}
											});
											break;
										case response.status === 202:
											window.alert('Data PO Error');
											this.button = false;
											this.progress = false;
											this.displayLoading = false;
											sessionStorage.removeItem(
												'tableSession'
											);
											this.selectedPo = [];
											break;
										case response.status === 203:
											window.alert(
												'Data PO already exists'
											);
											this.button = false;
											this.progress = false;
											this.displayLoading = false;
											sessionStorage.removeItem(
												'tableSession'
											);
											this.selectedPo = [];
											break;
										case response.status === 208:
											Swal.fire({
												title: "Failed To Submit",
												text: response.data.data,
												icon: "error", 
												showConfirmButton: false, 
												timer: 2000 
											}).then(() => {
												location.reload(); 
											});
											this.button = false;
											this.progress = false;
											this.displayLoading = false;
											// window.location.reload();
											break;
									}
								}
							);
						}
					);
				});
			} else {
				this.button = false;
				this.displaySubmit = true;
				this.auth = false;
			}
		},
		notAccept() {
			var isTest = true;
			if (this.selectedPo == null || this.selectedPo.length == 0) {
				this.displayPilih = true;
			} else {
				for (let i = 0; i < this.selectedPo.length; i++) {
					if (
						this.selectedPo[i].remarks3 == null ||
						this.selectedPo[i].remarks3.length == 0
					) {
						isTest = false;
						this.displayValidasi = true;
						break;
					}
				}
				if (isTest) {
					AuthService.whoAmi().then((response) => {
						var respondDate = this.formatDateDexa(new Date());
						// var date = new Date().toLocaleDateString("id-ID", options);
						var notAccept = {
							status: 'N',
							last_updated_by: response.data.data.id,
							last_updated_date: respondDate,
						};
						let something = [];
						// for (let i = 0 ; i < this.selectedPo.length ; i++){
						//     this.payloadNotAccept = Object.assign(this.selectedPo[i], notAccept)
						// }
						this.selectedPo.forEach((element) => {
							this.payloadNotAccept = Object.assign(
								element,
								notAccept
							);
							something.push(this.payloadNotAccept);
							element.name = 'NOT ACCEPT';
						});

						let payload = {
							isi: something,
						};
						PlanningOrder.notAcceptStatus(payload).then(
							(response) => { }
						);
						this.$toast.add({
							severity: 'success',
							summary: 'Status Change',
							detail: 'Change Status to Not Accept',
							life: 3000,
						});
						sessionStorage.removeItem('tableSession');
						this.selectedPo = [];
					});
				}
			}
		},
		confirm(status) {
			if (this.selectedPo == null || this.selectedPo.length == 0) {
				this.displayPilih = true;
			} else {
				AuthService.whoAmi().then((response) => {
					var last_updated_date = this.formatDateDexa(new Date());
					var lopez = {
						last_updated_by: response.data.data.id,
						last_updated_date: last_updated_date,
						status: status,
						response_by: this.respondBy,
						plan_order_id: parseInt(this.idValue),
						db_instance: sessionStorage.companydb,
					};
					var something = [];
					// let header = { id : parseInt(this.idValue), date : dateHeader, status : 'T', last_updated_by : response.data.data.id, last_updated_date : date}
					this.selectedPo.forEach((element) => {
						const index = this.planningOrderTable.findIndex(
							(something) =>
								something.plan_order_line_id ===
								element.plan_order_line_id
						);
						this.something = Object.assign(
							this.planningOrderTable[index],
							lopez
						);
						something.push(this.something);
						element.name = 'TO BE PROCESSED';
						if (status == 'C') {
							element.name = 'CANCELLED';
						}
					});
					var dateHeader = this.formatDateDexa(
						new Date(this.respondDate)
					);
					var statusHeader;
					var checkPO = this.AllTheSame(this.planningOrderTable);
					if (checkPO) {
						this.planningOrderTable.forEach((element) => {
							if (element.name == 'PROCESSED') {
								statusHeader = 'A';
							}else if (element.name == 'TO BE PROCESSED') {
								statusHeader = 'C';
							} else {
								statusHeader = 'D';
							}
						});
					} else if (!checkPO) {
						statusHeader = '';
					}
					const secretKey = 'some-unique-key';
					const simpleCrypto = new SimpleCrypto(secretKey);
					const decipherText = simpleCrypto.decrypt(sessionStorage.getItem('dropdownCompany'))
					let header = {
						id: this.idValue,
						date: dateHeader,
						status: statusHeader,
						last_updated_by: response.data.data.id,
						last_updated_date: last_updated_date,
						username: response.data.data.userid,
					};
					let payload = {
						isi: something,
						header: header,
						module: 'PlannoToSuppCancel',
						idUser: sessionStorage.getItem('idUser'),
						companyname: decipherText,
						sendpodata: [],
						idmodule: '1',
						sbjmessage:
							'Planning Order #' +
							this.planningOrderNum +
							' has been cancelled by ' + this.respondBy,
						// receiveddate: new Date(),
						recipient: [],
						status: 'Cancelled',
						notif_type: 'Supplier',
						dbInstance: null,
						orgIdComp: null,
						usersenderid: this.usersenderid,
						companyname: decipherText,
						vendorname: this.supplier,
						currenciesList: []
					};
					for (var id of payload.isi) {
						if (
							id.need_by_date == null ||
							id.need_by_date.length == 0
						) {
							id.need_by_date = '';
						} else if (
							id.need_by_date != null ||
							id.need_by_date.length > 0
						) {
							id.need_by_date = this.formatDateDexa(
								new Date(id.need_by_date)
							);
						} else if (
							id.confirm_ship_date == null ||
							id.confirm_ship_date.length == 0
						) {
							id.confirm_ship_date = '';
						} else if (id.confirm_ship_date.length > 1) {
							id.confirm_ship_date = this.formatDateDexa(
								new Date(id.confirm_ship_date)
							);
						} else if (id.remarks == null) {
							id.remarks = '';
						}
						if (id.remarks != null) {
							id.remarks = id.remarks;
						}
						PlanningOrder.confirmSupp(payload).then(
							(response) => { }
						);
					}
					this.$toast.add({
						severity: 'success',
						summary: 'Status Change',
						detail: 'Status Already Changed',
						life: 3000,
					});
					sessionStorage.removeItem('tableSession');
					this.selectedPo = [];
				});
			}
		},
		printPage() {
			let url = UrlTab() + "/printpopur"
			let printUrl = url;
			window.open(printUrl, 'Print', 'height=720,width=1280');
		},
	},
};
</script>
<style scoped lang="scss">
.outofstock {
	font-weight: 700;
	color: #ff5252;
	text-decoration: line-through;
}

.lowstock {
	font-weight: 700;
	color: #ffa726;
}

.instock {
	font-weight: 700;
	color: #66bb6a;
}

::v-deep(.row-accessories) {
	background-color: rgba(0, 0, 0, 0.15) !important;
}

:selection {
	// background-color: #ffffff;
	color: #ff5252;
}

// input{
//     text-align: right;
// }</style>
